import React from 'react'
import { graphql } from "gatsby"
import Layout from '../components/Layout'
import { RichText } from 'prismic-reactjs';
import { withPreview } from 'gatsby-source-prismic'

import styled from 'styled-components';

import tw from 'twin.macro' //eslint-disable-line


const BodyText = styled.div`
  ${tw`space-y-4`}
`

const SliceZones = ({section}) => { 

  switch (section.slice_type) {
    case 'image':
      return <img key={section.id} tw='pb-6' src={section.primary.image.url} alt={section.primary.image.alt} />;
    case 'text':
      return <BodyText key={section.id} tw='pb-6'><RichText render={section.primary.content.raw}/></BodyText>; 
    default:
      return null;
  }

};

const PagePage = ({ data }) => {

  const page = data.prismicPage.data;
  const title = page.title.raw[0].text;
  const details = page.details;
  const body = page.body;
  const banner = page.banner;

  return (
    <Layout>
      <div className="extraTop">
        <div>
          <img src={banner.url} alt='Home Page Banner' />
          <div tw="md:grid md:grid-cols-2 max-w-6xl mx-auto gap-3 md:gap-5 py-12 px-5">
            <div>
              <h1 tw='my-8 text-5xl lg:text-6xl xl:text-7xl font-light'>{ title }</h1>
              <dl>
                { details.map((detail, index) => {
                  return (
                    <div className="detail-item" key={ index } tw="flex py-1 text-sm items-baseline">
                      <dd tw="font-serif italic pr-3" dangerouslySetInnerHTML={ { __html: detail.name.html } } key={ index } ></dd>
                      <dt dangerouslySetInnerHTML={ { __html: detail.content.html } }></dt>
                    </div>
                  )
                }) }
              </dl>
            </div>
            <div tw='mt-16 md:mt-0'>
              {body.map(section => <SliceZones key={section.id} section={section} />)}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}


export const query = graphql`
  query($id: String!) {
    prismicPage(id: {eq: $id}) {
    id
    data {
      title {
        raw
      }
      details {
        name {
          html
        }
        content {
          html
        }
      }
      banner {
        url
        alt
      }
      body {
        ... on PrismicPageBodyImage {
          id
          slice_type
          primary {
            image {
              url
              alt
            }
            caption {
              html
            }
          }
        }
        ... on PrismicPageBodyText {
          id
          slice_type
          primary {
            content {
              raw
            }
          }
        }
      }
    }
  }
  }
`


export default withPreview(PagePage)
